import { onError } from "../libs/errorLib";
import { useAppContext } from '../libs/contextLib'
import InsightList from '../components/InsightList'
import { BsBrightnessHigh } from "react-icons/bs"
import { getInsights } from "../libs/interweave-api/project-insights";
import { useQuery } from "react-query";
import DebugPanel from "../components/DebugPanel"

export default function NewInterview() {
    const { DEBUG } = useAppContext();
    const { orgId } = useAppContext();
    const { isAuthenticated } = useAppContext();
    const { selectedProjectId } = useAppContext();

    const insights = useQuery(
        ['insights', selectedProjectId],
        async () => {
            try {
              return await getInsights(selectedProjectId);
            } catch (e) {
                onError(e);
            }
        }, {
          enabled: !!selectedProjectId && isAuthenticated
        }
    );

    function renderInsightsList(insights) {
        return (
            <>
                <InsightList insights={insights.sort((a,b) => a.CreatedAt < b.CreatedAt)} /> 
            </>
        );
    }

    return (
        <>
            <main className="-mt-24 pb-8">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h1 className="sr-only">Insights</h1>
                {/*Main 3 column grid */}
                <div className="">
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                    <section aria-labelledby="section-1-title">
                        {/* page content */}
                        <h2 className="sr-only" id="section-1-title">Insights</h2>
                        <div className="rounded-lg bg-white overflow-hidden shadow">
                        <div className="p-6">
                            <div className="flex items-center">
                                <BsBrightnessHigh className="h-4 w-4 mr-2 text-gray-600" />
                                <h3 className="flex-auto text-md font-medium text-gray-600">Insights</h3>
                            </div>

                            {/* Insights List */}
                            {renderInsightsList(insights.isSuccess ? insights.data : [])}
                            {insights.isLoading ? "Loading" : ""}
                        </div>
                        </div>
                    </section>
                    </div>
                </div>
                </div>
            </main>
            <DebugPanel display={DEBUG} isAuthenticated={isAuthenticated} orgId={orgId || null} selectedProjectId={selectedProjectId} />
        </>
    );
}