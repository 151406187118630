const config = {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-east-1",
      BUCKET: "interweave-app-uploads",
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://o3b0uhdvvh.execute-api.us-east-1.amazonaws.com/prod",
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_OC400AnAs",
      APP_CLIENT_ID: "52df6c64i0lul7i5oslr9j3jpl",
      IDENTITY_POOL_ID: "us-east-1:300b1a65-e516-468d-8715-0dc7e8ad939d",
    },
  };
  
  export default config;