import React, { useMemo, useRef, useEffect } from 'react'
import { Slate, Editable, ReactEditor, withReact, useSlate } from 'slate-react'
import { Editor, Transforms, Text, createEditor } from 'slate'
import { css } from '@emotion/css'
import { withHistory } from 'slate-history'
import { Button, Menu, Portal } from './slatecomponents'
import { Range } from 'slate'
import { BsTypeBold, BsTypeItalic, BsTypeUnderline } from 'react-icons/bs'

export default function SlateEditor({
    content,
    setContent
}) {
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    return (
        <Slate editor={editor} value={content} onChange={value => setContent(value)}>
            <HoveringToolbar />
            <Editable
                renderLeaf={props => <Leaf {...props} />}
                placeholder="Your interview notes..."
                className="mt-6 min-h-100 space-y-6 p-4 border shadow-sm block w-full focus:ring-blue-300 focus:border-blue-100 border-gray-200 rounded-md"
            />
        </Slate>
    )
}

const toggleFormat = (editor, format) => {
    const isActive = isFormatActive(editor, format)
    Transforms.setNodes(
        editor,
        { [format]: isActive ? null : true },
        { match: Text.isText, split: true }
    )
}

const isFormatActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n => n[format] === true,
        mode: 'all',
    })
    return !!match
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underlined) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const HoveringToolbar = () => {
    const ref = useRef()
    const editor = useSlate()

    useEffect(() => {
        const el = ref.current
        const { selection } = editor

        if (!el) {
            return
        }

        if (
            !selection ||
            !ReactEditor.isFocused(editor) ||
            Range.isCollapsed(selection) ||
            Editor.string(editor, selection) === ''
        ) {
            el.removeAttribute('style')
            return
        }

        const domSelection = window.getSelection()
        const domRange = domSelection.getRangeAt(0)
        const rect = domRange.getBoundingClientRect()
        el.style.opacity = 1
        el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
        el.style.left = `${rect.left +
            window.pageXOffset -
            el.offsetWidth / 2 +
            rect.width / 2}px`
    })

    return (
        <Portal>
            <Menu
                ref={ref}
                className={css`
          padding: 8px 7px 6px;
          position: absolute;
          z-index: 1;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          opacity: 0;
          background-color: #222;
          border-radius: 4px;
          transition: opacity 0.75s;
        `}
            >
                <FormatButton format="bold" icon="format_bold" isBold={true} isItalic={false} isUnderline={false} />
                <FormatButton format="italic" icon="format_italic" isBold={false} isItalic={true} isUnderline={false} />
                <FormatButton format="underlined" icon="format_underlined" isBold={false} isItalic={false} isUnderline={true} />
            </Menu>
        </Portal>
    )
}

const FormatButton = ({ format, isBold, isItalic, isUnderline }) => {
    const editor = useSlate()
    return (
        <Button
            reversed
            active={isFormatActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleFormat(editor, format)
            }}
        >
            { isBold && <BsTypeBold className="h-4 w-4 text-white" /> }
            { isItalic && <BsTypeItalic className="h-4 w-4 text-white" /> }
            { isUnderline && <BsTypeUnderline className="h-4 w-4 text-white" /> }
        </Button>
    )
}