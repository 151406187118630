import { Transition } from '@headlessui/react'

export default function Slideover({
    title,
    description,
    isSlideoverOpen,
    setIsSlideoverOpen,
    ...props
}) {


    return (
        <Transition
            show={isSlideoverOpen}
            enter="transform transition ease-in-out duration-100 sm:duration-100"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-100 sm:duration-100"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
        >
            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex" aria-labelledby="slide-over-heading">
                        {/*
                        Slide-over panel, show/hide based on slide-over state.

                        Entering: "transform transition ease-in-out duration-500 sm:duration-700"
                        From: "translate-x-full"
                        To: "translate-x-0"
                        Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
                        From: "translate-x-0"
                        To: "translate-x-full"
                    */}

                        <div className="w-screen max-w-md">
                            <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                                <div className="py-6 px-4 bg-gradient-to-r from-blue-500 to-blue-400 sm:px-6">
                                    <div className="flex items-center justify-between">
                                        <h2 id="slide-over-heading" className="text-lg font-medium text-white">
                                            {title}
                                        </h2>
                                        <div className="ml-3 h-7 flex items-center">
                                            <button
                                                className="bg-blue-400 rounded-md text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                onClick={() => setIsSlideoverOpen(!isSlideoverOpen)}>
                                                <span className="sr-only">Close panel</span>
                                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <p className="text-sm text-white">
                                            {description}
                                        </p>
                                    </div>
                                </div>
                                <div className="relative flex-1 py-6 px-4 sm:px-6">
                                    
                                    <div className="absolute inset-0 py-6 px-4 sm:px-6">
                                        <div className="h-full" aria-hidden="true">
                                            {/* Children Content */}
                                            { props.children }
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </Transition>
    );
}