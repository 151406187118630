import { useState } from 'react';
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { Transition } from '@headlessui/react'
import { BsPeopleCircle } from 'react-icons/bs'
import { projectsList } from '../libs/interweave-api/projects'
import { useQuery } from "react-query";
import { onError } from "../libs/errorLib";

function Nav() {
  const { orgId } = useAppContext();
  const { userHasAuthenticated } = useAppContext();
  const { setSelectedProjectId } = useAppContext();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);
  const history = useHistory();

  const projects = useQuery(
    ['projects', orgId], 
    async () => {
        try {
            const ret =  await projectsList(orgId);

            // Temp solution to set the selectedProject
            setSelectedProjectId(ret[0].ProjectId);

            return ret;
        } catch (e) {
            onError(e);
        }
    }, {
      refetchOnWindowFocus: false
    }
  );

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);
    history.push("/login");
  }

  return (
    <header className="pb-24 bg-gradient-to-r from-green-400 to-blue-500">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="relative py-5 flex items-center justify-center lg:justify-between">
          {/*Logo */}
          <div className="absolute left-0 flex-shrink-0 lg:static">
            <Link to="/">
              <span className="sr-only">Interweave</span>
              <img className="h-8 w-auto" src="/interweave-white-logo.png" alt="Workflow" />
            </Link>
          </div>

          {/*Right section on desktop */}
          <div className="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
            {/*Profile dropdown */}
            <div className="ml-4 relative flex-shrink-0">
              <div>
                <button
                  type="button"
                  className="rounded-full flex p-1 text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100 hover:bg-opacity-10" id="user-menu" aria-haspopup="true"
                  onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}>
                  <span className="sr-only">Open user menu</span>
                  <BsPeopleCircle className="h-6 w-6 text-white" />
                </button>
              </div>
              <Transition
                show={isProfileMenuOpen}
                enter=""
                enterFrom=""
                enterTo=""
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                  <Link to="/signout" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
                    onClick={handleLogout}
                  >Sign out</Link>
                </div>
              </Transition>
            </div>
          </div>

          {/*Menu button */}
          <div className="absolute right-0 flex-shrink-0 lg:hidden">
            {/*Mobile menu button */}
            <button className="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              {/*
              Heroicon name: menu

              Menu open: "hidden", Menu closed: "block"
            */}
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              {/*
              Heroicon name: x

              Menu open: "block", Menu closed: "hidden"
            */}
              <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div className="hidden lg:block border-t border-white border-opacity-20 py-5">
          <div className="grid grid-cols-3 gap-8 items-center">
            <div className="col-span-2">
              <nav className="flex space-x-4">

                {/* Project Selector */}
                <div className="relative inline-block text-white text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10">
                  <button type="button"  className="flex items-center" id="options-menu" aria-expanded="true" aria-haspopup="true"
                    onClick={() => setIsProjectMenuOpen(!isProjectMenuOpen)}
                  >
                    <span>My First Project</span>&nbsp;
                    <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  <Transition
                    show={isProjectMenuOpen}
                    enter=""
                    enterFrom=""
                    enterTo=""
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <div className="py-1" role="none">
                        { projects.status === 'success' && (
                          projects.data.map(project => (
                            <span key="project.ProjectName" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{project.ProjectName}</span>
                          )))
                        }
                        {
                          projects.status === 'loading' && (
                            <span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Loading</span>    
                          )
                        }
                      </div>
                    </div>
                  </Transition>
                </div>
                
                <Link to="/" className="text-white text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10">
                  Dashboard
                </Link>
                <Link to="/interviews" className="text-white text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10">
                  Interviews
                </Link>
                <Link to="/insights" className="text-white text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10">
                  Insights
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/*
      Mobile menu overlay, show/hide based on mobile menu state.

      Entering: "duration-150 ease-out"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "duration-150 ease-in"
        From: "opacity-100"
        To: "opacity-0"
    */}
      <div className="z-20 fixed inset-0 bg-black bg-opacity-25 lg:hidden" aria-hidden="true"></div>

      {/*
      Mobile menu, show/hide based on mobile menu state.

      Entering: "duration-150 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-150 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    */}
      <div className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top lg:hidden">
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
          <div className="pt-3 pb-2">
            <div className="flex items-center justify-between px-4">
              <div>
                <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
              </div>
              <div className="-mr-2">
                <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close menu</span>
                  {/*Heroicon name: x */}
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1 uppercase">
              <Link to="/" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                Dashboard
            </Link>
              <Link to="/interviews" className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800">
                Interviews
            </Link>
            </div>
          </div>
          <div className="pt-4 pb-2">
            <div className="mt-3 px-2 space-y-1">
              <Link
                to="/signout"
                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                role="menuitem"
                onClick={handleLogout} >
                Sign out
          </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Nav;