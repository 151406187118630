import { onError } from "../libs/errorLib";
import { useAppContext } from '../libs/contextLib'
import InterviewList from '../components/InterviewList'
import { BsFileRichtext, BsPlusCircleFill } from "react-icons/bs"
import { Link } from "react-router-dom";
import { getInterviews } from "../libs/interweave-api/interviews";
import { useQuery } from "react-query";

export default function NewInterview() {
    const { isAuthenticated } = useAppContext();
    const { selectedProjectId } = useAppContext();

    const interviews = useQuery(
        ['interviews', selectedProjectId],
        async () => {
            try {
              return await getInterviews(selectedProjectId);
            } catch (e) {
                onError(e);
            }
        }, {
          enabled: !!selectedProjectId && isAuthenticated
        }
    );

    function renderInterviewsList(interviews) {
        return (
            <>
                <InterviewList interviews={interviews.sort((a,b) => a.createdAt < b.createdAt)} /> 
            </>
        );
    }

    return (
        <main className="-mt-24 pb-8">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Interviews</h1>
            {/*Main 3 column grid */}
            <div className="">
                <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                <section aria-labelledby="section-1-title">
                    {/* page content */}
                    <h2 className="sr-only" id="section-1-title">Interviews</h2>
                    <div className="rounded-lg bg-white overflow-hidden shadow">
                    <div className="p-6">
                        <div className="flex items-center">
                            <BsFileRichtext className="h-4 w-4 mr-2 text-gray-600" />
                            <h3 className="flex-auto text-md font-medium text-gray-600">Recent Interviews</h3>
                            <Link to="/interviews/new"><BsPlusCircleFill className="h-4 w-4 mr-2 text-blue-500" /></Link>
                        </div>

                        {/* Interviews List */}
                        {renderInterviewsList(interviews.isSuccess ? interviews.data : [])}
                        {interviews.isLoading ? "Loading" : ""}
                    </div>
                    </div>
                </section>
                </div>
            </div>
            </div>
        </main>
    );
}