import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import SignOut from "./containers/SignOut";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
//import Signup from "./containers/Signup";
import NewInterview from "./containers/NewInterview";
import Interview from "./containers/Interview";
import Interviews from "./containers/Interviews"
import Insights from "./containers/Insights"
import Nav from './containers/Nav'
import Footer from './containers/Footer'

export default function Routes() {
    return (
        <Switch>
            <UnauthenticatedRoute exact path="/login">
                <Login />
            </UnauthenticatedRoute>
            {/*<UnauthenticatedRoute exact path="/signup">
                <Signup />
            </UnauthenticatedRoute>*/}
            <AuthenticatedRoute exact path="/interviews/new">
                <>
                    <Nav />
                    <NewInterview />
                    <Footer />
                </>
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/interviews/:interviewId">
                <>
                    <Nav />
                    <Interview />
                    <Footer />
                </>
            </AuthenticatedRoute>
            <AuthenticatedRoute exect path="/interviews">
                <>
                    <Nav />
                    <Interviews />
                    <Footer />
                </>
            </AuthenticatedRoute>
            <AuthenticatedRoute exect path="/insights">
                <>
                    <Nav />
                    <Insights />
                    <Footer />
                </>
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/">
                <>
                    <Nav />
                    <Home />
                    <Footer />
                </>
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/signout">
                <SignOut />
            </AuthenticatedRoute>
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}