import React from "react";
import { BsArrowRepeat } from "react-icons/bs";

export default function LoaderButton({
    isLoading,
    className = "",
    disabled = false,
    ...props
}) {
    return (
        <button
            className={`px-5 py-3 mt text-base leading-6 
                bg-blue-500 rounded-md font-medium text-white focus:outline-none 
                hover:bg-blue-700 hover:shadow-none h-12 ${className}`}
            {...props}
            disabled={disabled || isLoading}>
        {isLoading && <BsArrowRepeat className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />}
        {props.children}
        </button>
    )
}