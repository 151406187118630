import React from "react";
import { Link } from "react-router-dom";

export default function InterviewList({
    interviews,
    className = "",
    ...props
}) {

    return (
        <ul className="divide-y divide-gray-200">
            {interviews.map(({ InterviewId, Title, CreatedAt }) => (
                <li key={InterviewId} className="py-4 flex">
                    <Link to={`/interviews/${InterviewId}`} className="">
                        <div className="">
                            <p className="text-sm font-medium text-gray-900">
                                {Title}
                            </p>
                            <p className="text-xs text-gray-500">Created: {new Date(CreatedAt).toLocaleString()}</p>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>    
    );
}