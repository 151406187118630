import { API } from "aws-amplify";

//
// POST /projects/{projectId}/interviews
//
export async function createInterview(newInterview) {
    return API.post("interweave", `/projects/${newInterview.projectId}/interviews`, {
        body: newInterview
    });
}

//
// GET /projects/{id}/interviews
//
export async function getInterviews(projectId) {
    return API.get("interweave", `/projects/${projectId}/interviews`);
}

//
// GET /projects/{projectId}/interviews/{interviewId}
//
export async function getInterview(projectId, interviewId) {
    return API.get("interweave", `/projects/${projectId}/interviews/${interviewId}`);
}

//
// PUT /projects/{projectId}/interviews/{interviewId}
//
export async function updateInterview(interview) {
    return API.put("interweave", `/projects/${interview.projectId}/interviews/${interview.interviewId}`, {
        body: interview
    });
}

//
// DELETE /projects/{projectId}/interviews/{interviewId}
//
export async function deleteInterview(interview) {
    return API.del("interweave", `/projects/${interview.projectId}/interviews/${interview.interviewId}`, {
        body: interview
    });
}