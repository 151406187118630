import './index.css';
import Routes from "./Routes";
import { useEffect, useState } from "react";
import { AppContext } from "./libs/contextLib"
import { Auth } from "aws-amplify";
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const orgId = "f1c425e0-8486-11eb-a4eb-079ef759cbfe";
  const DEBUG = true; // DEBUG STATE

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, orgId, DEBUG, selectedProjectId, setSelectedProjectId }}>
        <Routes />
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
