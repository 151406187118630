import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import LoaderButton from "../components/LoaderButton";
import Slideover from "../components/Slideover";
import DeleteModal from "../components/DeleteModal"
import { BsPeopleFill, BsPlusCircleFill, BsTrash, BsFileRichtext, BsBrightnessHigh } from 'react-icons/bs'
import SlateEditor from '../components/SlateEditor'
import { serialize } from '../libs/serializeLib';
import moment from 'moment';
import { getInterview, updateInterview, deleteInterview } from "../libs/interweave-api/interviews";
import { createInsight, getInsights, editInsight, deleteInsight } from "../libs/interweave-api/insights"
import { useQueryClient, useQuery, useMutation } from "react-query";
import { useAppContext } from '../libs/contextLib'
import DebugPanel from "../components/DebugPanel"

export default function Interview() {
    const queryClient = useQueryClient();
    const { DEBUG } = useAppContext();
    const { orgId } = useAppContext();
    const { isAuthenticated } = useAppContext();
    const { selectedProjectId } = useAppContext();
    const { interviewId } = useParams();
    const history = useHistory();
    const [isSlideoverOpen, setIsSlideoverOpen] = useState(false);
    const [isEditSlideoverOpen, setIsEditSlideoverOpen] = useState(false);
    const [isDeleteInsightModalOpen, setIsDeleteInsightModalOpen] = useState(false);
    const [isDeleteInterviewModalOpen, setIsDeleteInterviewModalOpen] = useState(false);
    const [title, setTitle] = useState("Loading...");
    const [notes, setNotes] = useState([
        {
            type: 'paragraph',
            children: [
                {
                    text:
                        '',
                },
            ],
        }
    ]);
    const [insight, setInsight] = useState("");
    const [insightId, setInsightId] = useState("")
    const evidenceText = "";
    
    const interview = useQuery(
        ['interviews', selectedProjectId, interviewId],
        async () => {
            try {
                const ret = await getInterview(selectedProjectId, interviewId);
                return ret;
            } catch (e) {
                onError(e);
            }
        }, {
            enabled: !!selectedProjectId && isAuthenticated,
            onSuccess: (data) => {
                setNotes(data.Notes);
                setTitle(data.Title);
          }
        }
    );

    const insights = useQuery(
        ['insights', interviewId],
        async () => {
            try {
                const ret = await getInsights(interviewId);
                return ret;
            } catch (e) {
                onError(e);
            }
        }, {
            enabled: !!selectedProjectId && isAuthenticated
        }
    );

    const interviewMutation = useMutation(updateInterview);

    const deleteMutation = useMutation(deleteInterview, {
        onSuccess: () => {
            history.push("/interviews/");
        }
    });

    const insightCreateMutation = useMutation(createInsight, {
        onSuccess: (data) => {
            insights.data.push(data);
        }
    });

    const insightEditMutation = useMutation(editInsight, {
        onSuccess: (data) => {
            insights.data.forEach(i => {
                if(i.InsightId === data.insightId) {
                    i.Insight = data.insight;
                }
            });
        }
    });

    const insightDeleteMutation = useMutation(deleteInsight, {
        onSuccess: () => {
            queryClient.invalidateQueries(['insights', interviewId]);
            setIsDeleteInsightModalOpen(!isDeleteInsightModalOpen); 
            setInsight("");
            setIsEditSlideoverOpen(!isEditSlideoverOpen);
        }
    });
 
    function validateForm() {
        return serialize(notes).length > 0;
    }

    function handleOpenNewInsightSlideover(event, text) {
        event.preventDefault();

        setIsSlideoverOpen(!isSlideoverOpen);
    }

    function handleOpenEditInsightSlideover(event, insightId) {
        event.preventDefault();

        setInsightId(insightId);

        if(!isEditSlideoverOpen) {
            setInsight(insights.data.filter(i => i.InsightId === insightId)[0].Insight);            
        }

        setIsEditSlideoverOpen(!isEditSlideoverOpen);
    }

    function handleSaveInterview(event) {
        event.preventDefault();

        const updatedInterview = {
            projectId: selectedProjectId,
            interviewId: interviewId,
            title: title,
            notes: notes,
            notesSerialized: serialize(notes)
        };

        interviewMutation.mutate(updatedInterview);
    }

    function handleOpenDeleteInterviewModal(event) {
        event.preventDefault();

        setIsDeleteInterviewModalOpen(true);
    }

    function handleDeleteInterview(event) {
        event.preventDefault();
        
        const deleteInterview = {
            projectId: selectedProjectId,
            interviewId: interviewId
        };

        deleteMutation.mutate(deleteInterview);
    }

    function handleSubmitInsight(event) {
        event.preventDefault();

        const newInsight = {
            interviewId: interviewId,
            insight: insight
        };

        insightCreateMutation.mutate(newInsight);  

        setInsight("");
        setIsSlideoverOpen(!isSlideoverOpen);
    }

    function handleEditInsight(event) {
        event.preventDefault();

        const editInsight = {
            interviewId: interviewId,
            insightId: insightId,
            insight: insight
        };

        // Update server
        insightEditMutation.mutate(editInsight);

        setInsight("");
        setIsEditSlideoverOpen(!isEditSlideoverOpen);
    }

    function handleOpenDeleteInsightModal(event) {
        event.preventDefault();

        setIsDeleteInsightModalOpen(true);
    }

    function handleDeleteInsight(event) {
        event.preventDefault();
        
        const deleteInsight = {
            interviewId: interviewId,
            insightId: insightId
        };

        insightDeleteMutation.mutate(deleteInsight);
    }

    function renderInsightsList(items) {
        return (
            <>
            {items.sort((a,b) => a.CreatedAt < b.CreatedAt).map(insight => 
                <li 
                    className="relative bg-white py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
                    key={insight.InsightId} >
                    <div className="mt-2">
                        <p className="line-clamp-2 text-sm text-gray-600">
                            {insight.Insight}
                        </p>
                    </div>
                    <div className="space-x-3 flex">
                        <time dateTime={insight.CreatedAt} className="whitespace-nowrap text-sm text-gray-500 flex">
                            {moment(insight.CreatedAt).fromNow()}
                            {insight.UpdatedAt !== null && (
                            <span>
                                <svg className="h-4 w-4 ml-1 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <title>{"Updated " + moment(insight.UpdatedAt).fromNow()}</title>
                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                </svg>
                            </span>
                            )}
                        </time>
                        <span className="text-gray-500 text-sm">&middot;</span>
                        <button type="button" onClick={(e) => handleOpenEditInsightSlideover(e, insight.InsightId)} className="text-gray-500 text-sm">Edit</button>
                    </div>
                </li>
                )
            }
            {items.length === 0 && (
                <div className="text-sm text-gray-500 py-5">No insights have been created. Create insights from interviews so you can identify product trends and demand.</div>
            )}
            </>
        )
    }

    return (
        <>
            <main className="-mt-24 pb-8">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h1 className="sr-only">Page title</h1>
                    {/*Main 3 column grid */}
                    <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                        {/*Left Column*/}
                        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                            <section aria-labelledby="section-1-title">
                                <h2 className="sr-only" id="section-1-title">New Interview</h2>
                                <div className="rounded-lg bg-white overflow-hidden shadow">
                                    <div className="p-6">
                                        {/* page content */}
                                        {interview.isSuccess && (
                                            <form>
                                                <div className="flex flex-row items-center">
                                                    <div className="flex-auto">
                                                        <h3 className="text-2xl text-gray-700">{title}</h3>
                                                        <p className="text-sm text-gray-500">Interview created {moment(interview.data.CreatedAt).fromNow()}{!!interview.data.UpdatedAt ? " and last updated " + moment(interview.data.UpdatedAt).fromNow() : ""}.</p>
                                                    </div>
                                                    <div className="flex flex-shrink-0 ">
                                                        <LoaderButton
                                                            type="submit"
                                                            isLoading={interviewMutation.isLoading}
                                                            disabled={!validateForm()}
                                                            onClick={handleSaveInterview}
                                                        >
                                                            Save
                                                        </LoaderButton>
                                                    </div>
                                                    <div className="flex relative flex-shrink-0 ml-4">
                                                        <button
                                                            className="rounded-full flex p-1 text-sm ring-2 ring-gray-400 hover:ring-red-600 bg-white hover:text-red-600 text-gray-400"
                                                            onClick={handleOpenDeleteInterviewModal}>
                                                            <span className="sr-only">Delete Interview</span>
                                                            <BsTrash className="h-4 w-4" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-6 pb-1 space-y-6 border-b">
                                                    <div className="flex items-center">
                                                        <BsPeopleFill className="h-4 w-4 mr-2 text-gray-600" />
                                                        <span className="flex-auto text-sm font-semibold text-gray-600">Interviewees</span>
                                                    </div>
                                                </div>
                                                <div className="mt-3 space-y-6">
                                                    <div className="flex items-center">
                                                        <BsPlusCircleFill className="h-4 w-4 mr-2 text-blue-500" />
                                                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                                                            None Selected
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="mt-6 pb-1 space-y-6 border-b">
                                                    <div className="flex items-center">
                                                        <BsFileRichtext className="h-4 w-4 mr-2 text-gray-600" />
                                                        <span className="flex-auto text-sm font-semibold text-gray-600">Interview Notes</span>
                                                    </div>
                                                </div>
                                                <div className="space-y-3">
                                                    <p className="mt-2 text-sm text-gray-500 mr-4">Edit your interview notes below. Highlight, tag, and save insights from this interview to add to your evidence locker.</p>
                                                </div>
                                                <div>
                                                    <SlateEditor
                                                        content={notes}
                                                        setContent={setNotes}
                                                        onChange={newNotes => setNotes(newNotes)}
                                                    />
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/*Right column */}
                        <div className="grid grid-cols-1 gap-4">
                            <section aria-labelledby="section-2-title">
                                <h2 className="sr-only" id="section-2-title">Insights</h2>
                                <div className="rounded-lg bg-white overflow-hidden shadow">
                                    <div className="p-6">
                                        <div className="pb-1 space-y-6 border-b">
                                            <div className="flex items-center">
                                                <BsBrightnessHigh className="h-4 w-4 mr-2 text-gray-600" />
                                                <span className="flex-auto text-sm font-semibold text-gray-600">Insights</span>
                                                <button
                                                    className=""
                                                    onClick={(e) => handleOpenNewInsightSlideover(e, "")}
                                                    >
                                                    <span className="sr-only">New Insight</span>
                                                    <BsPlusCircleFill className="h-4 w-4 mr-2 text-blue-500 hover:text-blue-700" />
                                                </button>
                                            </div>
                                        </div>    
                                        {!insights.isLoading && (
                                            <ul className="divide-y divide-gray-200">
                                                {renderInsightsList(insights.isSuccess ? insights.data : [])}
                                            </ul>
                                        )}
                                        {insights.isLoading && (
                                            <div>
                                               Loading...
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
            { /* New Insight Slidover */ }
            <Slideover
                title="New Insight"
                description="Insights are your observations and key learnings. Each insight should be distinct, an interview can have many insights."
                isSlideoverOpen={isSlideoverOpen}
                setIsSlideoverOpen={setIsSlideoverOpen}>
                <div>
                    <form className="space-y-6">
                        { !!evidenceText && (
                        <div>
                            <label htmlFor="evidence" className="block text-sm font-medium text-gray-900">
                                Evidence
                            </label>
                            
                            <div className="mt-1">
                                <textarea
                                    id="evidence"
                                    name="evidence"
                                    rows="6"
                                    readOnly
                                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-300 focus:border-blue-300 border-gray-300 rounded-md"
                                    value={evidenceText} />
                            </div>
                        </div>
                        )}
                        <div>
                            <label htmlFor="insight" className="block text-sm font-medium text-gray-900">
                                Insight
                            </label>
                            <div className="mt-1">
                                <textarea
                                    id="insight"
                                    name="insight"
                                    rows="10"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-300 focus:border-blue-300 border-gray-300 rounded-md"
                                    value={insight}
                                    onChange={(e) => setInsight(e.target.value) } />
                            </div>
                        </div>
                        <div className="flex-shrink-0 flex justify-end">
                            <LoaderButton
                                type="submit"
                                isLoading={insightCreateMutation.isLoading}
                                onClick={handleSubmitInsight}
                            >
                                Save
                            </LoaderButton>
                            <button type="button" onClick={(e) => handleOpenNewInsightSlideover(e)} className="ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </Slideover>
            { /* Edit Insight Slidover */ }
            <Slideover
                title="Edit Insight"
                description="Insights are your observations and key learnings. Each insight should be distinct, an interview can have many insights."
                isSlideoverOpen={isEditSlideoverOpen}
                setIsSlideoverOpen={setIsEditSlideoverOpen}>
                <div>
                    <form className="space-y-6">
                        { !!evidenceText && (
                        <div>
                            <label htmlFor="evidence" className="block text-sm font-medium text-gray-900">
                                Evidence
                            </label>
                            
                            <div className="mt-1">
                                <textarea
                                    id="evidence"
                                    name="evidence"
                                    rows="6"
                                    readOnly
                                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-300 focus:border-blue-300 border-gray-300 rounded-md"
                                    value={evidenceText} />
                            </div>
                        </div>
                        )}
                        <div>
                            <label htmlFor="insight" className="block text-sm font-medium text-gray-900">
                                Insight
                            </label>
                            <div className="mt-1">
                                <textarea
                                    id="insight"
                                    name="insight"
                                    rows="10"
                                    className="block w-full shadow-sm sm:text-sm focus:ring-blue-300 focus:border-blue-300 border-gray-300 rounded-md"
                                    value={insight}
                                    onChange={(e) => setInsight(e.target.value) } />
                            </div>
                        </div>
                        <div className="flex-shrink-0 flex justify-end flex-row items-center">
                            <LoaderButton
                                type="submit"
                                isLoading={insightEditMutation.isLoading}
                                onClick={handleEditInsight}
                            >
                                Save
                            </LoaderButton>
                            <button type="button" onClick={(e) => handleOpenEditInsightSlideover(e)} 
                                className="ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-12" >                                
                                Cancel
                            </button>
                            <div className="flex relative flex-shrink-0 ml-4">
                                <button
                                    className="rounded-full flex p-1 text-sm ring-2 ring-gray-400 hover:ring-red-600 bg-white hover:text-red-600 text-gray-400"
                                    onClick={(e) => handleOpenDeleteInsightModal(e)}>
                                    <span className="sr-only">Delete Insight</span>
                                    <BsTrash className="h-4 w-4" />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Slideover>
            <DeleteModal 
                title = "Delete Insight"
                message = "Are you sure you want to permanently delete this insight?"
                deleteHandler = {handleDeleteInsight}
                isDeleteModalOpen = {isDeleteInsightModalOpen}
                setIsDeleteModalOpen = {setIsDeleteInsightModalOpen}
                className="" />
            <DeleteModal 
                title = "Delete the Interview"
                message = "Are you sure you want to permanently delete this interview??"
                deleteHandler = {handleDeleteInterview}
                isDeleteModalOpen = {isDeleteInterviewModalOpen}
                setIsDeleteModalOpen = {setIsDeleteInterviewModalOpen}
                className="" />
            <DebugPanel display={DEBUG} isAuthenticated={isAuthenticated} orgId={orgId} selectedProjectId={selectedProjectId} />
        </>
    );
}