import { Link } from "react-router-dom";

export default function InsightList({
    insights,
    className = "",
    ...props
}) {

    return (
        <ul className="divide-y divide-gray-200">
            {insights.map(({ InsightId, Insight, CreatedAt }) => (
                <li key={InsightId} className="py-4 flex">
                    <Link to={`/insights/${InsightId}`} className="">
                        <div className="">
                            <p className="text-sm font-medium text-gray-900">
                                {Insight}
                            </p>
                            <p className="text-xs text-gray-500">Created: {new Date(CreatedAt).toLocaleString()}</p>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>    
    );
}