import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton"
import { onError } from "../libs/errorLib";
import { useFormFields } from "../hooks/formFieldsHook"
import { useQuery } from "react-query";


function Login() {
    const { userHasAuthenticated } = useAppContext();
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: ""
    });

    const { isFetching, refetch } = useQuery(
        ['authUser', fields.email, fields.password], 
        async () => {
            try {
                await Auth.signIn(fields.email, fields.password)
                userHasAuthenticated(true);
            } catch (e) {
                onError(e);
            }
        },
        {
            enabled: false
        },
    );

    async function AuthenticateUser(event) {
        event.preventDefault();
        refetch();
    }

    function validateForm() {
        return fields.email.length > 0 && fields.password.length > 0;
    }

    return (
        <div className="flex flex-col h-screen bg-gradient-to-r from-green-400 to-blue-500">
            <div className="grid place-items-center mx-2 my-20 sm:my-auto">
                <div className="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-4/12 2xl:w-4/12 
                    px-6 py-10 sm:px-10 sm:py-6 
                    bg-white rounded-lg shadow-md lg:shadow-lg">
        
                    <div className="flex items-center">
                        <img src="../interweave-blue-logo.png" className="h-8 w-8 mr-2" alt="" />
                        <h2 className="flex-auto font-light tracking-widest text-xl lg:text-xl text-blue-500">
                            Login to Interweave
                        </h2>
                    </div>
        
                    <form className="mt-10" onSubmit={AuthenticateUser}>
                        <label htmlFor="email" className="block text-sm font-semibold text-gray-600">Email</label>
                        <input id="email" type="email" name="email" placeholder="email address" autoComplete="email"
                            autoFocus
                            className="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required
                            value={fields.email}
                            onChange={handleFieldChange} />
        
                        <label htmlFor="password" className="block mt-6 text-sm font-semibold text-gray-600">Password</label>
                        <input id="password" type="password" name="password" placeholder="password" autoComplete="current-password"
                            className="block w-full py-3 px-1 mt-2 mb-4
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required
                            value={fields.password}
                            onChange={handleFieldChange} />
        
                        <div className="flex justify-around">
                            <LoaderButton
                                type="submit"
                                className=""
                                isLoading={isFetching}
                                disabled={!validateForm()}>
                                    Login
                            </LoaderButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;