export default function DebugPanel({
    interviews,
    className = "",
    ...props
}) {
    if(props.display) {
        return (
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className='bg-green-50 border-l-4 border-green-400 p-4 rounded-md mt-10 {DEBUG ? "" : "hidden"}'>
                    <p className="text-sm font-medium text-green-800">Debug Data</p>
                    <ul className="list-disc list-inside mt-2 text-sm text-green-700">
                        <li>{props.isAuthenticated ? (<span>Logged In</span>) : (<span>Logged Out</span>)}</li>
                        <li><span>Org ID: {props.orgId}</span></li>
                        <li><span>Project: {props.selectedProjectId}</span></li>
                    </ul>
                </div>
            </div>
        )
      } else { return <></> }
}