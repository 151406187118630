import { useState } from 'react';
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { BsPeopleFill, BsPlusCircleFill } from 'react-icons/bs'
import { serialize } from '../libs/serializeLib';
import SlateEditor from '../components/SlateEditor'
import { useMutation } from 'react-query'
import { createInterview } from '../libs/interweave-api/interviews'
import { useAppContext } from '../libs/contextLib'


export default function NewInterview() {
    const initialValue = [
        {
            type: 'paragraph',
            children: [
                {
                    text:
                        '',
                },
            ],
        }
    ]
    const { selectedProjectId } = useAppContext();
    const history = useHistory();
    const [title, setTitle] = useState("");
    const [notes, setNotes] = useState(initialValue);    

    const interviewMutation = useMutation(createInterview, {
        onSuccess: (data) => {
            history.push("/interviews/" + data.InterviewId);
        }
    });

    function validateForm() {
        return title.length > 0 && serialize(notes).length > 0
    }

    function handleSubmit(event) {
        event.preventDefault();

        const newInterview = {
            projectId: selectedProjectId,
            title: title,
            notes: notes,
            notesSerialized: serialize(notes)
        };

        interviewMutation.mutate(newInterview);
    }

    return (
        <main className="-mt-24 pb-8">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <h1 className="sr-only">Page title</h1>
                {/*Main 3 column grid */}
                <div className="">
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                        <section aria-labelledby="section-1-title">
                            {/* page content */}
                            <h2 className="sr-only" id="section-1-title">New Interview</h2>
                            <div className="rounded-lg bg-white overflow-hidden shadow">
                                <div className="p-6">
                                    <form onSubmit={handleSubmit}>
                                        <div className="flex flex-row">
                                            <div className="flex-auto">
                                                <h3 className="text-lg font-medium">New Interview</h3>
                                                <p className="mt-2 text-sm text-gray-500">Record your interview notes below. Highlight, tag and save insights from this interview to add to your evidence locker.</p>
                                            </div>
                                            <div className="flex flex-shrink-0 ">
                                                <LoaderButton
                                                    type="submit"
                                                    isLoading={interviewMutation.status === 'loading'}
                                                    disabled={!validateForm()}
                                                    >
                                                    Save
                                                </LoaderButton>
                                            </div>
                                        </div>
                                        <div className="mt-6 pb-1 space-y-6">
                                            <input 
                                                type="text" 
                                                value={title}
                                                className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" 
                                                placeholder="Interview title..." 
                                                onChange={e => setTitle(e.target.value)}
                                                />
                                        </div>
                                        <div className="mt-6 pb-1 space-y-6 border-b">
                                            <div className="flex items-center">
                                                <BsPeopleFill className="h-4 w-4 mr-2 text-gray-600" />
                                                <span className="flex-auto text-sm font-semibold text-gray-600">Interviewees</span>
                                            </div>
                                        </div>
                                        <div className="mt-3 space-y-6">
                                            <div className="flex items-center">
                                                <BsPlusCircleFill className="h-4 w-4 mr-2 text-blue-500" />
                                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                                                    None Selected
                                                </span>
                                            </div>
                                        </div>
                                        <div className="mt-6 pb-1 space-y-6 border-b">
                                            <div className="flex items-center">
                                                <svg className="h-4 w-4 mr-2 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg>
                                                <span className="flex-auto text-sm font-semibold text-gray-600">Interview Notes</span>
                                            </div>
                                        </div>
                                        <div>
                                            <SlateEditor
                                                content={notes}
                                                setContent={setNotes}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}