import { API } from "aws-amplify";

//
// POST /projects/{projectId}/interviews
//
export async function createInsight(newInsight) {
    return API.post("interweave", `/interviews/${newInsight.interviewId}/insights`, {
        body: newInsight
    });    
}

//
// GET /interviews/{interviewId}/Insights
//
export async function getInsights(interviewId) {
    return API.get("interweave", `/interviews/${interviewId}/insights`);
}

//
// PUT /interviews/{interviewId}/Insights
//
export async function editInsight(editInsight) {
    return API.put("interweave", `/interviews/${editInsight.interviewId}/insights/${editInsight.insightId}`, {
        body: editInsight
    });
}

//
// DELETE /interviews/{interviewId}/Insights
//
export async function deleteInsight(deleteInsight) {
    return API.del("interweave", `/interviews/${deleteInsight.interviewId}/insights/${deleteInsight.insightId}`);
}